<template>
  <div
    v-if="usps?.length"
    class="usps"
    :class="placementClass"
  >
    <slot />

    <h3
      v-if="props?.title"
      class="usps__title"
    >
      {{ props.title }}
    </h3>

    <ul
      class="usps__list"
      :class="[{ 'usps--horizontal': horizontal, 'usps--vertical': !horizontal }]"
    >
      <li
        v-for="(usp, index) in usps"
        :key="index"
        class="usps__item"
        :class="{ 'usps__item--active': index === activeIndex }"
      >
        <IconCheck
          class="usps__icon usps__icon--check"
        />

        <component
          :is="getElement(usp.Link)"
          :to="usp.Link"
          class="usps__label"
        >
          {{ label(usp) }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import layoutQuery from '~layers/app/graphql/layout.gql'
import { AppLink } from '#components'

const props = withDefaults(defineProps<{
  title?: string
  usps: array
  horizontal?: boolean
  placement?: string
}>(), {
  title: () => '',
  horizontal: () => false,
  placement: () => '',
})

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

const activeIndex = ref(0)

const placementClass = computed(() => {
  return props.placement ? 'usps--' + props.placement : undefined
})

onMounted(() => {
  if (props.usps) {
    setInterval(() => {
      activeIndex.value++
      if (activeIndex.value >= props.usps.length) {
        activeIndex.value = 0
      }
    }, 5000)
  }
})

const label = (usp) => {
  if (!usp?.Description?.length) {
    return usp.Label.replace('{dynamic-delivery-promise}', deliveryPromise.value.content || '')
  }
  return usp.Description.replace('{dynamic-delivery-promise}', deliveryPromise.value.content || '')
}

const getElement = (url: string | null) => {
  return url ? AppLink : 'span'
}

const day = data?.value?.dayOfWeek
const deliveryPromise = computed(() => {
  const dpKey = Object.keys(data?.value?.deliveryPromise || {}).find(key => key.includes(`_${day}`))

  if (!dpKey || !data?.value?.deliveryPromise[dpKey]) {
    return ''
  }

  const [name, key] = dpKey.split('_')

  return {
    name,
    key,
    content: data?.value?.deliveryPromise[dpKey],
  }
})
</script>

<!-- <style src="~layers/app/components/App/Usps.css" /> -->
<style>
@import url("~layers/app/components/App/Usps.css");
</style>
